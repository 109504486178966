import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from 'styled-components/macro'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { PrimaryLink as PrimaryLinkBase, LogoLink } from "../../components/headers/light.js";
import supabase from "helpers/SupabaseClient";
import { useAuth } from "helpers/Auth";
import Pagination from "components/pagination/pagination";
import * as SupabaseAPI from "helpers/SupabaseAPI";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap justify-center`
const PostContainer = styled.div`
  ${tw`mt-2 w-3/4 sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-2`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-2 border-2 rounded-t-lg rounded-l-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-bold text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const PaginationContainer = tw.div`flex justify-center`
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`flex justify-start`
const Input = tw.input`w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;


async function hasDateColumn(tableName) {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select('date');


    if (data === null) return false;
    return true;
  } catch (error) {
    return false; // Return false in case of an error
  }
}

export default ({
  heading = "Videos",
  table = "service"
}) => {
  const [userId, setUserId] = useState();

  // Pagination
  const offset = 15;


  const { user } = useAuth();
  const [serviceVids, setServiceVids] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemCount, setPageItemCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  const [table_ord, setTableOrd] = useState("id");

  useEffect(() => {
    async function setUser() {
      try {
        const { data, error } = await supabase
          .from("access_report")
          .select("*")
          .eq("email", user.email)

        setUserId(data[0].registration_number);
      } catch (error) {
        console.error(error);
      }
    };
    setUser();

    async function fetchData(liveVid) {
      var ord = "id";
      await hasDateColumn(table).then((value) => {
        if (value == true) {
          ord = "date";
        }
      });

      try {

        //Load Pagination Count
        const data = await SupabaseAPI.getPaginatedData(table, pageNumber, offset, ord)
        const count = await SupabaseAPI.getTableRowCount(table)
        setPageItemCount(count)

        console.log(data)

        const vids = data.filter(item => item.status === 'published');

        if (liveVid.title != undefined && pageNumber === 1) {
          const liveVids = vids.slice();
          if (liveVid.date !== vids[0].title.split(" ")[0]){
            liveVids.unshift(liveVid);
            setServiceVids(liveVids);
          }
        } else {
          setServiceVids(vids);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    async function fetchLiveData() {
      try {
        const service_playlist = "PLOZKPu82muDxocKX_YsJKgunVl5bH-oKB";
        const playlistApi = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=1&playlistId=${service_playlist}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
        fetch(playlistApi)
          .then(response => response.json())
          .then(data => {
            if (data?.items) {
              if (data?.items.length > 0) {
                const content = data.items[0].snippet
                const liveData =
                {
                  "title": content.title,
                  "video_key": content.resourceId.videoId,
                  "date": content.title.split(" ")[0]
                }
                console.log(liveData);
                fetchData(liveData);
              } else {
                fetchData({});
              }
            }
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
    if (showingSearchResults == false) {
      if (table === "service" || table === "daily_bread") {
        fetchLiveData()
      } else {
        fetchData({});
      }
    }


  }, [pageNumber, showingSearchResults]);

  const formatDate = (datetime, title_date) => {
    if (datetime === undefined) { return ""; }
    if (title_date.startsWith(datetime)) { 
      return datetime;
    } else {
      const parts = datetime.split("-");
      if (table === "service" && new Date(datetime) > new Date('2024-05-19')) {
        const day = new Date(datetime).getDay();
        if (day === 0) {
          return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]} Sunday Service`;
        } else if (day === 2) {
          return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]} Tuesday Service`;
        }else if (day === 3) {
          return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]} Wednesday Service`;
        }
      }
      return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`
    };
  };


  const clearSearch = async () => {
    setShowingSearchResults(false);
  }

  const paginationChange = (change, page) => {
    setPageNumber(page);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const fetchData = async () => {
      const ord = await SupabaseAPI.hasDateColumn(table) ? "date" : "id";
      setTableOrd(ord)
      try {
        const data = await SupabaseAPI.getPaginatedData(table, pageNumber, 15, ord);
        const count = await SupabaseAPI.getTableRowCount(table);
        setPageItemCount(count);
        setServiceVids(data.filter(item => item.status === 'published'));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [pageNumber, table, showingSearchResults]);


  const searchByTitle = async (title) => {
    try {
      const data = title.trim() ? await SupabaseAPI.searchByColumn(table, 'title', title, table_ord) : await SupabaseAPI.getAllEntries(table, table_ord);
      setServiceVids(data.filter(item => item.status === 'published'));
      setShowingSearchResults(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setServiceVids([]);
      setShowingSearchResults(false);
    }
  }

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);  // Directly use the value from the event to update the searchQuery state
    if (debounceTimeout) clearTimeout(debounceTimeout);
    const newTimeout = setTimeout(() => {
      searchByTitle(value);  // Trigger the search using the updated query
    }, 100);  // Using a 500ms debounce to limit excessive API calls
    setDebounceTimeout(newTimeout);
  };


  return (
    <Container>
      <Content>
      {/* <ButtonContainer>
        <LoadMoreButton onClick={(e) => window.history.back()}>Back</LoadMoreButton>
      </ButtonContainer> */}
          <HeadingRow>
            <Heading>
              <HighlightedText>{heading}</HighlightedText>
            </Heading>
            
          </HeadingRow>
        <SearchContainer style={{ margin: '20px' }}>
          <Input
            type="text"
            style={{
              fontSize: '18px',  // Larger font size
              border: '2px solid pink',  // Pink border
              padding: '8px',
              width: '100%'
            }}
            placeholder="Search by title..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchContainer>
          <Posts>
            {serviceVids.map((post, index) => (
              <PostContainer key={index} featured={true}>
                <Post className="group" as="a" href={`/video-material/${post.video_key}-->${post.title}`}>
                  <Info>
                    {post.date && <Category>{`${formatDate(post.date, post.title)}`}</Category>}
                    <Title>
                      {(table === 'service') ? post.title.split(' ').slice(1).join(' ') : post.title}
                    </Title>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
        <PaginationContainer>
          {<Pagination totalLength={pageItemCount} pageSize={15} onChange={paginationChange}></Pagination>}
        </PaginationContainer>
      </Content>
    </Container>
  );
};
