import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import { PrimaryButton } from "../../components/misc/Buttons.js"
import Watermark from "helpers/Watermark.js";
import { useParams } from 'react-router-dom';
import supabase from "helpers/SupabaseClient";
import ReactMarkdown from 'react-markdown';
import { m } from "framer-motion";
import PdfViewer from "helpers/PdfViewer.js";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900 mb-0 sm:mb-10 mb-5 `;
const Text = styled.div`
  ${tw`text-lg  text-gray-500`}
  ${tw`text-lg  text-gray-500`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;


const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``

export default ({ 
  navLinks = [],
  table = 'pdfs'
}) => {
  const { url } = useParams();
  const [material, setMaterial] = useState([]);
  const [education, setEducation] = useState("");
  const [illustrations, setIllustrations] = useState([]);

  const chapters = table != 'pdfs';

  useEffect(() => {
    console.log(chapters);
    async function fetchData() {
      console.log(table);
      try {
        const { data, error } = await supabase
          .from(table)
          .select('*')
          .eq('url', url)
        console.log(data);
        const words = data.filter(item => item.status === 'published');

        console.log(words);
        setMaterial(words);

      } catch (err) {
        console.error('Error fetching data:', err);
      }
    }
    fetchData();
  }, []);

  var title, contents;
  const formattedHeading = material.length > 0 ? (() => {
      title = material[0].title;
      contents = chapters ? material[0].audio : material[0].table_of_contents;
      return title;
    })() : '';

  console.log(contents);
  var parsedData = [];
  if (contents && chapters == false) {
    console.log(contents.blocks);
    for (var block in contents.blocks) {
      const text = contents.blocks[block].data.text.trim();
      const jsonString = text.endsWith(',') ? text.slice(0, -1) : text;
      console.log(jsonString);
      parsedData.push(JSON.parse(jsonString));
    };
  }
  const file_url = (material.length > 0 ? `https://zegleedmcpswaxynxnta.supabase.co/storage/v1/object/public/assets/${material[0].file}.pdf` : "")
  console.log(file_url);
  const media = chapters ? contents : parsedData;
  console.log(media);
  console.log(chapters);

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoIcon={icon} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{<><HighlightedText>{formattedHeading}</HighlightedText></>}</Heading>
          </HeadingRow>
          <Text>
            <Watermark></Watermark>
            <PdfViewer 
              pdf={
                {
                  file: file_url,
                  contents: media
                }
              }
              audio={chapters}
            />
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};