import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../../components/headers/light.js";
import icon from "../../../images/lra.svg"
import supabase from "helpers/SupabaseClient";
import Pagination from 'components/pagination/pagination.js'
import { SearchBar } from "components/search/search";

import * as SupabaseAPI from "helpers/SupabaseAPI";
import MaterialList from 'components/cards/MaterialList.js'

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap justify-center`
const PostContainer = styled.div`
  ${tw`mt-2 w-3/4 sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-2`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-2 border-2 rounded-t-lg rounded-l-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-bold text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const ButtonContainer = tw.div`flex justify-center`
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`mt-5 ml-5`




export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  headingText = "Chairman's Life Giving Words",
  imageSrc = 'https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
}) => {
  const [visible, setVisible] = useState(15)

  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemCount, setPageItemCount] = useState(0);
  const offset = 20;

  const paginationChange = (change, page) => {
    console.log(change)
    setPageNumber(page);
  }

  const onLoadMoreClick = () => {
    setVisible((v) => v + 15)
  }
  const [articles, setArticles] = useState([]);
  const [capLength, setCapLength] = useState(63);

  const [showingSearchResults, setShowingSearchResults] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {

        //Load Pagination Count
        const count = await SupabaseAPI.getTableRowCount('chairmans_life_giving_words');
        const data = await SupabaseAPI.getPaginatedData('chairmans_life_giving_words', pageNumber, offset, 'date');

        setPageItemCount(count);

        const words = data.filter(item => item.status === 'published');
        
        console.log(words);
        setArticles(words);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    if (showingSearchResults == false){
      fetchData();
    }
    
    
    if (window.innerWidth <= 640) {
      setCapLength(63); // sm
    } else if (window.innerWidth <= 768) {
      setCapLength(110); // md
    } else {
      setCapLength(180); // lg
    }

  }, [pageNumber, showingSearchResults]);

  const formattedDate = (date) => {
    const parts = date.split("-");
    return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
  };

  const searchByTitle = async (title) => {
    try {
      //Load Pagination Count
      const data = await SupabaseAPI.searchByColumn('chairmans_life_giving_words', 'title', title, 'date')
      const words = data.filter(item => item.status === 'published');
      setArticles(words);
      setShowingSearchResults(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  

  const clearSearch = async () => {
    setShowingSearchResults(false);
  }


  return (
    
    <MaterialList headingText={"Wise Questions, Wise Answers"} table='wisequestions' />
    
    // <AnimationRevealPage>
    //   <StyledHeader links={navLinks} logoLink={logoLink} />
    //   <Container>
    //     <ContentWithPaddingXl>
    //       <HeadingRow>
    //         <Heading>
    //           <HighlightedText>{headingText}</HighlightedText>
    //         </Heading>
    //       </HeadingRow>
    //       <Posts>
    //         {articles.map((post, index) => (
    //           <PostContainer key={index} featured={true}>
    //             <Post className="group" as="a" href={`/chairmans-words/${post.url}`}>
    //               <Info>
    //                 <Category>{`JSS-${formattedDate(post.date)}`}</Category>
    //                 <Title>
    //                   {post.title.length > capLength
    //                     ? post.title.slice(0, capLength) + "..."
    //                     : post.title}
    //                 </Title>
    //               </Info>
    //             </Post>
    //           </PostContainer>
    //         ))}
    //       </Posts>
    //       <ButtonContainer>
    //         <Pagination totalLength={pageItemCount} pageSize={15} onChange={paginationChange}></Pagination>
    //       </ButtonContainer>
    //     </ContentWithPaddingXl>
    //   </Container>
    // </AnimationRevealPage>

//     <AnimationRevealPage>
//       <StyledHeader links={navLinks} logoLink={logoLink} />
//       <Container>
//         <ContentWithPaddingXl>
//           <HeadingRow>
//             <Heading>
//               <HighlightedText>{headingText}</HighlightedText>
//               <SearchContainer>
//               <SearchBar onSearch={(value) => {searchByTitle(value)}} clearSearch={showingSearchResults} onClearSearch={() => {clearSearch()}}></SearchBar>
//               </SearchContainer>
//             </Heading>
            
//           </HeadingRow>
//           <Posts>
//             {articles.map((post, index) => (
//               <PostContainer key={index} featured={true}>
//                 <Post className="group" as="a" href={`/chairmans-words/${post.url}`}>
//                   <Info>
//                     <Category>{`JSS-${formattedDate(post.date)}`}</Category>
//                     <Title>
//                       {post.title.length > capLength
//                         ? post.title.slice(0, capLength) + "..."
//                         : post.title}
//                     </Title>
//                   </Info>
//                 </Post>
//               </PostContainer>
//             ))}
//           </Posts>
//           <ButtonContainer>
//           {showingSearchResults == false && <Pagination totalLength={pageItemCount} pageSize={15} onChange={paginationChange}></Pagination>}
//           </ButtonContainer>
//         </ContentWithPaddingXl>
//       </Container>
//     </AnimationRevealPage>
  );
}