import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { SectionHeading } from 'components/misc/Headings'
import { PrimaryButton } from 'components/misc/Buttons'
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../../components/headers/light.js";
import icon from "../../images/lra.svg"
import supabase from "helpers/SupabaseClient";
import Pagination from 'components/pagination/pagination.js'
import { SearchBar } from "components/search/search";

import * as SupabaseAPI from "helpers/SupabaseAPI";

const HeadingRow = tw.div`flex justify-between items-center sm:flex-col md:flex-row`;
const Heading = tw.h2`text-2xl sm:text-4xl font-black tracking-wide text-center text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap justify-center`
const PostContainer = styled.div`
  ${tw`mt-2 w-3/4 sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-2`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100`
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-2 border-2 rounded-t-lg rounded-l-lg`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = tw.div`mt-1 font-bold text-xl text-gray-900 group-hover:text-primary-500 transition duration-300`
const Description = tw.div``

const PaginationContainer = tw.div`flex justify-center`
const ButtonContainer = tw.div`flex justify-end`
const LoadMoreButton = tw(PrimaryButton)``
const StyledHeader = styled(Header)`
  ${tw`p-0 max-w-none w-full`}
  ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
`;
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const SearchContainer = tw.div`flex justify-start`
const Input = tw.input`w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

async function hasDateColumn(tableName) {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select('date');

    if (data === null) return false;
    return true;
  } catch (error) {
    return false; // Return false in case of an error
  }
}

export default ({
  navLinks = [],
  logoLink = (
    <LogoLink href="/home">
      <img src={icon} alt="logo" />
      LightRainAir
    </LogoLink>
  ),
  headingText = "Material",
  table = "chairmans_life_giving_words",
  asc = false
}) => {
  // Pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageItemCount, setPageItemCount] = useState(0);
  const offset = 20;

  const paginationChange = (change, page) => {
    console.log(change)
    setPageNumber(page);
    window.scrollTo(0, 0);
  }
  const [searchQuery, setSearchQuery] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [articles, setArticles] = useState([]);
  const [capLength, setCapLength] = useState(63);
  const [showingSearchResults, setShowingSearchResults] = useState(false);
  const [table_ord, setTableOrd] = useState("id");

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      searchByTitle(value);
    }, 100);  // 100 ms delay for debouncing

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hasDate = await hasDateColumn(table);
        const ord = hasDate ? "date" : "id";
        setTableOrd(ord);

        const count = await SupabaseAPI.getTableRowCount(table);
        const data = await SupabaseAPI.getPaginatedData(table, pageNumber, offset, ord, asc);
        setPageItemCount(count);
        const publishedWords = data.filter(item => item.status === 'published');
        
        setArticles(publishedWords);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pageNumber, table]);

  useEffect(() => {
    const updateCapLength = () => {
      const width = window.innerWidth;
      if (width <= 640) {
        setCapLength(63);  // Small devices
      } else if (width <= 768) {
        setCapLength(110);  // Medium devices
      } else {
        setCapLength(180);  // Large devices
      }
    };

    updateCapLength();
    window.addEventListener('resize', updateCapLength);

    return () => {
      window.removeEventListener('resize', updateCapLength);
    };
  }, []);

  useEffect(() => {
    // Load all entries on initial component mount
    searchByTitle('');
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        var ord = "id";
        await hasDateColumn(table).then((value) => {
          if (value == true) {
            ord = "date";
            setTableOrd("date");
          }
        });

        //Load Pagination Count
        const count = await SupabaseAPI.getTableRowCount(table);
        const data = await SupabaseAPI.getPaginatedData(table, pageNumber, offset, ord, asc);

        setPageItemCount(count);

        const words = data.filter(item => item.status === 'published');

        setArticles(words);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();

    if (window.innerWidth <= 640) {
      setCapLength(63); // sm
    } else if (window.innerWidth <= 768) {
      setCapLength(110); // md
    } else {
      setCapLength(180); // lg
    }

  }, [pageNumber]);

  const formattedDate = (date) => {
    try {
      const parts = date.split("-");
      if (headingText === "Chairman's Life Giving Words") return `CHJN's Letter-${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
      return `${parseInt(parts[0]) - 1983}${parts[1]}${parts[2]}`;
    } catch (error) {
      return null;
    }
  };
  const searchByTitle = async (title) => {
    try {
      let data;
      if (!title.trim()) {
        // Fetch all entries if the search query is empty
        data = await SupabaseAPI.getAllEntries(table, table_ord);
      } else {
        // Perform a full-text search if there is a query
        data = await SupabaseAPI.searchByColumn(table, 'title', title, table_ord);
      }
      const words = data.filter(item => item.status === 'published');
      setArticles(words);
      setShowingSearchResults(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setArticles([]); // Handle error by clearing results
      setShowingSearchResults(false);
    }
  }



  const clearSearch = async () => {
    setShowingSearchResults(false);
  }

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} logoLink={logoLink} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>
              <HighlightedText>{headingText}</HighlightedText>
            </Heading>
          </HeadingRow>
          
          <SearchContainer style={{ margin: '20px' }}>
              <Input
                type="text"
                style={{
                  fontSize: '18px',  // Larger font size
                  border: '2px solid pink',  // Pink border
                  padding: '8px',
                  width: '100%'
                }}
                placeholder="Search by title..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
          </SearchContainer>

          <Posts>
            {articles.map((post, index) => (
              <PostContainer key={index} featured={true}>
                <Post className="group" as="a" href={`/${table.replaceAll("_", "-")}/${post.url}`}>
                  <Info>
                    {post.date && <Category>{`${formattedDate(post.date)}`}</Category>}
                    <Title>
                      {post.title.length > capLength
                        ? post.title.slice(0, capLength) + "..."
                        : post.title}
                    </Title>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          <PaginationContainer>
            <Pagination totalLength={pageItemCount} pageSize={15} onChange={paginationChange}></Pagination>
          </PaginationContainer>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
}